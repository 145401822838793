html {
  scroll-behavior: smooth;
}
.App {
  text-align: center;
}

.depsTableData tr:hover {
  background-color: #c4c4c450;
}

.depsTableData tr:hover td {
  background-color: transparent; /* or #000 */
}

.swal2-container {
  z-index: 1500 !important;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.header {
  padding-bottom: 20px;
  border-bottom: 1px solid #3E4649;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.imageDataFix img {
  max-width: 100%;
}

.shadowMenuBottom {
  -webkit-box-shadow: 0px 33px 42px -25px rgba(135,135,135,0.64);
  -moz-box-shadow: 0px 33px 42px -25px rgba(135,135,135,0.64);
  box-shadow: 0px 33px 42px -25px rgba(135,135,135,0.64);
}

.onHoverMenuItem:hover {
    background: rgba(255, 225, 106, 0.5);

}
.onHoverMenuItem.active {
  background: rgba(255, 225, 106, 0.5);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.trueMark {
  width: 40px;
  height: 40px;
  background-color: #27AE6050;
  border-radius: 12px;

}

.trueMark img {
  width: 18px;
  opacity: 1;
  height: 15px;
  padding: 12px;
  fill: #27AE60;
}

.falseMark {
  background-color: #F86D7050;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  justify-content: center;
  align-content: center;
  display: flex;
  align-items: center;
}

.downloadMark {
  background-color: #FFE16A;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  cursor: pointer;
}

.grayed {
  background-color: #f1efec;
  cursor: initial;
}

.uploadingContent .uploadHeader {
  font-size: 20px;
  font-weight: bold;
}

.uploadBar {
  background: #EEEEEE;
  width: 100%;
  height: 13px;
  border-radius: 10px;
}
.uploadBar .innerBar {
  background: #6bd096;
  background: linear-gradient(90deg, #aafacd, #6FCF97 17%, #13dc69 34%, #6bd096 51%, #aafacd 68%, #6FCF97 85%, #13dc69);
  background-size: 300% 100%;
  border-radius: 10px;
  height: 13px;
  text-align: center;
  min-width: 7%;
  font-size: 10px;
  -webkit-animation: progress-animation 2s linear infinite;
  animation: progress-animation 2s linear infinite;
}
.notificationCounter {
  font-size: 16px;
  line-height: 46px;
  margin-left: 10px;
}

@-webkit-keyframes progress-animation {
  0% {
    background-position: 100%;
  }
  100% {
    background-position: 0;
  }
}

@keyframes progress-animation {
  0% {
    background-position: 100%;
  }
  100% {
    background-position: 0;
  }
}

.falseMark img {
  width: 18px;
  opacity: 1;
  height: 15px;
  fill: #FFE16A;
}

.headerDark {
  background-color: #0A1929 !important;
  border-color: #0A1929 !important;
}

.headerDark .rdw-option-wrapper {
  background-color: gray !important;
  border-color: #0A1929 !important;
  color: #fff;
}

.headerDark .rdw-image-modal  {
  box-shadow: none;
    background-color: #0A1929 !important;
    border-color: black !important;
}
.headerDark .rdw-emoji-wrapper {
  box-shadow: none;
  background-color: #0A1929 !important;
  border-color: black !important;
}

.headerDark .rdw-colorpicker-modal {
  box-shadow: none;
  background-color: #0A1929 !important;
  border-color: black !important;
  overflow-x: hidden;
}

.headerDark .rdw-link-modal{
  box-shadow: none;
  background-color: #0A1929 !important;
  border-color: black !important;
}

.headerDark .rdw-embedded-modal {
    box-shadow: none;
    background-color: #0A1929 !important;
    border-color: black !important;
}

.headerDark .rdw-emoji-modal {
    box-shadow: none;
    background-color: #0A1929 !important;
    border-color: black !important;
}

.headerDark .rdw-colorpicker-modal-options {
  overflow-x: hidden;
  -ms-overflow-x: hidden;
}

.headerDark .rdw-dropdown-wrapper {
  background-color: gray !important;
  border-color: #0A1929 !important;
  color: #fff;
}
.headerDark .rdw-dropdownoption-default {
  background-color: gray !important;
  border-color: #0A1929 !important;
  color: #fff;
}
.headerDark .rdw-option-wrapper img {
  fill: #fff;
}

.downloadMark img {
  width: 18px;
  opacity: 1;
  height: 15px;
  padding: 12px;
  fill: #FFE16A;
}

.tableContainer {
  height: calc(100% - 100px);
  width: 100%;
  display: flex;
  flex-direction: column;
  top: 20px;
}

.tableContentContainer{

  width: 100%;
  height: 90%;
  margin-top: 12px;
  color: #212B36;
  border-radius: 10px;
  overflow: scroll;
  box-shadow: 0 0 12px 4px rgba(145, 158, 171, .24), 0 16px 32px -4px rgba(145, 158, 171, .24);
}

.swal2-html-container {
  overflow: initial !important;
}

#documentHolderItem:fullscreen {
  background-color: #fff;
  overflow: scroll;
}
#documentHolderItem:-webkit-full-screen {
  background-color: #fff;
  overflow: scroll;
}
#documentHolderItem:-moz-full-screen {
  background-color: #fff;
  overflow: scroll;
}
.placeholder-ul{
  visibility: hidden;
}
.customSelect {

  position: relative;

}

.customSelect label {
  font-size: 16px;
  color: rgba(0,0,0,.6);
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 25px;
  /*transform: translateY(-50%);*/
  transition: all 0.2s ease 0s;
}

.disabledEditor p {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  color: #fff
}


.contactPersonText {
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 7px;
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 13px;
  font-weight: 350;
}

.disabledEditor {

  border-radius: 10px;
  font-size: 22px;
  position: absolute;
  text-align: center;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 97%;
  height: 580px;
  background-color: rgba(0,0,0,.4);
  z-index: 99999;
}

.customSelect label.selected {
  top: 13px;
  left: 25px;
  background-color: #fff;
  font-size: 12px;
  /*transform: translateY(0);*/
}

.confirmCustom {
  color: #000 !important;
  padding-right: 40px !important;
  padding-left: 40px !important;

}

.cancelCustom {
  padding-right: 40px !important;
  padding-left: 40px !important;
  color: #000 !important
}

.tableView {

  border: none;
  box-shadow: none;
  width: 95%;
  margin-left: 2.5%;
  margin-top: 2%;
  top: 20px;
  border-collapse: collapse;
}
.tableView .sticky {
  position: sticky;
  top: 0;
}

.tableView .head {
  border-color: green;
  border-radius: 10px;
  height: 76px
}

.tableTh {
  text-align: start;
  border-radius: 0px;
  background-color: #F2F2F2;
  position: sticky;
  overflow: visible;
  top: 0;
  z-index: 10;
  padding: 0;
  margin: 0;
  border: none;
  color: #828282;
}

.head > .tableTh:first-child {
  border-radius: 10px 0 0 10px !important;
}
.head > .tableTh:last-child {
  border-radius: 0 10px 10px 0 !important;
}



.ml-10{
  margin-left: 10px;
}

.tableTr {
  margin-top: 12px;
  height: 50px;
}

.checkboxColor{
  color: #FFE16A !important;
  fill: #000 !important;
}

.demo-editor{
  max-height: 250px;
}

.contenteditor{
  max-height: 450px;
  min-height: 450px;
}


.buttonPDF{
  width: 44px;
  height: 44px;
  background: white;
  border: 0;
  font: inherit;
  font-size: 0.8em;
  cursor: pointer;
  border-radius: 4px;
}

.buttonPDFFinish {
  cursor: pointer;
  width: 70px;
  height: 44px;
  background: white;
  border: 0;
  font: inherit;
  font-size: 0.8em;
  border-radius: 4px;
}

.buttonPDFFinish:hover{
  background-color: #e6e6e6;
}

.buttonPDF:hover{
  background-color: #e6e6e6;
}

.pageControl {
  position: absolute;
  bottom: 1%;
  left: 50%;
  background: white;
  opacity: 0;
  transform: translateX(-50%);
  transition: opacity ease-in-out 0.2s;
  box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
  border-radius: 4px;
  z-index: 990;
}

.react-pdf__Document:hover .pageControl {
  opacity: 1;
}

.react-pdf__Document{
  position: relative;
}

.react-pdf__Page{
  max-height: 842px;
  overflow: hidden;
}
.react-pdf__message{
  position: absolute !important;
  z-index: 9;
  height: 842px;
  widows: 595;
  overflow: hidden;
  display: block;
}
.prevPage {
  position: absolute !important;
  z-index: 9;
  height: 842px;
  width: 595px;
  overflow: hidden;
  display: block;
}

.leftPrevButton{
  height: 842px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  z-index: 22;
  cursor: pointer;
}

.rightNextButton{
  top: 0;
  right: 0;
  position: absolute;
  height: 842px;
  width: 100px;
  z-index: 22;
  cursor: pointer;
}
.transformImage {
  -webkit-transform:rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.imageClass{
  position: absolute;
  margin-top: -3px
}
.infoClass {
  position: absolute;
  margin-left: 5px;
  margin-top: 2px;
}
.openCol {
  margin-left: 20px;
}


@keyframes growProgressBar {
  0%, 33% { --pgPercentage: 0; }
  100% { --pgPercentage: var(--value); }
}

@property --pgPercentage {
  syntax: '<number>';
  inherits: false;
  initial-value: 0;
}

div[role="progressbar"] {
  --size: 12rem;
  --fg: var(--color);
  --bg: #def;
  --pgPercentage: var(--value);
  animation: growProgressBar 3s 1 forwards;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  display: grid;
  place-items: center;
  background:
          radial-gradient(closest-side, white 80%, transparent 0 99.9%, white 0),
          conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0)
;
  font-family: Helvetica, Arial, sans-serif;
  font-size: calc(var(--size) / 5);
  color: var(--fg);
}

div[role="progressbar"]::before {
  counter-reset: percentage var(--inner);
  content: counter(percentage);
}

.zIndexm1 {
  z-index: 1;
}

.zIndexu2 {
  z-index: 2;
}
