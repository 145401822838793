body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ranking {
  list-style: none;
  margin-top: 15px;


}

.ranking .videoTitle {
  width: 50%;
  font-style: normal;
  font-size: 16px;
  line-height: 17px;
  font-weight: bold;
}

.rankingBar {
  border-radius: 5px;
  width: 100%;
  height: 5px;
}
.rankingBars {
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 30px;
}
.endFlex{
  margin-right: 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  width: 23%;
  justify-self: flex-end;
}

.rankingBar.green {
  background: #6FCF97;
}
.rankingBar.lightGreen {
  background: #D1F7A0;
}
.rankingBar.orange {
  background: #F9CB38;
}
.rankingBar.lightOrange {
  background: #FFF7CD;
}
.rankingBar.red {
  background: #F86D70;
}

.emailContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.emailContent .leftSide {
  width: 80%;
  justify-content: center;
  align-content: center;
  display: flex;
  flex-direction: column;
}

.emailContent .title {
  font-size: 35px;
  text-align: center;
  font-weight: bold;
  margin-top: 50px
}

.tableForEmail {
  border-collapse: collapse;
  padding-bottom: 20px;
  width: 100%;
}


.emailContent .accounts {
  text-align: left;
  margin-left: 30px;
}

.emailContent .content {
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  margin-left: 10px;
}

.viewBox {
  box-shadow: 0 8px 16px rgba(145, 158, 171, 0.24);
  border: 1px solid #E0E0E0;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
}

.fromMy {
  height: 300px;
}

.viewBoxRanking {
  box-shadow: 0 8px 16px rgba(145, 158, 171, 0.24);
  border: 1px solid #E0E0E0;
  border-radius: 10px;
  margin-top: 20px;
}

.viewBoxTitle {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.rankingTitle {
  padding: 20px;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 18px;
  line-height: 17px;
  color: #000;

}

.circleHolder {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.circleHolder .rankingCircle:first-child {
  margin-top: 60px;
}

.circleHolder .rankingCircle:nth-child(2){
  margin-top: 30px;
}

.rankingCircle {
  border-radius: 50px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(4px);
  width: 100px;
  height: 100px;
  text-align: center;
}

.rankingCircle .rank {
  font-size: 36px;
  line-height: 43px;
  color: #000;
  padding-top: 10px
}

.rankingCircle .userID {
  font-size: 14px;
  line-height: 17px;
  color: #000;
}

.rankingCircle .points {
  font-size: 12px;
  padding-top: 5px;
  line-height: 14px;
  color: #828282;
}

.rankingCircle.gray {
  background-color: rgba(242, 242, 242, 0.8);
}

.rankingCircle.yellow{
  background-color: rgba(255, 225, 106, 0.80);
}

.top10Me {
  border-style: dotted;
  border-color: rgb(176, 176, 176);
}

.rankingListItem{
  width: 100%;
  border-bottom: 1px solid #E0E0E0;
  display: flex;
  padding: 25px 0;
  flex-direction: row;

}
.rankingList{
  padding: 0 20px;
  margin: 0;
}

.rankingListItem.myPoint {
  border-top: 1px solid #828282;
  border-bottom: none;
}


.departmentRankings {
  margin:0;
  padding:0;

}

.departmentRankingItem {
  list-style: none;
  padding: 0;
  margin: 10px 0 20px 0;
}

.departmentRankingItem .title {
  color: #828282;
  font-size: 16px;
  line-height: 17px;
  font-weight: bold;

}

.trophyListHolder {
  height: 90%;
  width: 100%;
  display: flex;
  overflow-x: auto;
  flex-direction: row;
  justify-content: space-between;
}



.trophyHolder {
  cursor: pointer;
  width: 200px;
  min-width: 200px;
  min-height: 200px;
  height: 200px;
  position: relative;
  margin-right: 30px;
}
.trophyHolder .inner {
  /*z-index: -1;*/
  position: absolute;
  top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-content: center;
}

.trophyHolder p {
  text-align: center;
  font-weight: bold;
}

.userStatsFooter {
  border-top: 1px solid #E0E0E0;
  height: 80px;
  display: flex;
  align-items: center;
}

.userStatsFooter .name {
  margin-left: 10px;
  font-size: 14px;
}
.userStatsFooter .dataMarker {
  border-radius: 50px;
  height:15px;
  width: 15px;
  margin-left: 20px;
  background-color: #2F80ED;
}

.userStatsFooter .dataMarker.dark{
  background-color: #FFC107;
}

.departmentRankingItem .rank {
  margin-top: 5px;
  border-radius: 0 30px 30px 0;
  background-color: #6FCF97;
  height: 60px;
  min-width: 1%;
}

.departmentRankingItem .rank p {
  line-height: 30px;
  margin-left: 15px;
  color: #828282;
  position: absolute;
}


.rankingList .rankingListItem .rank{
  margin-right: 20px;
  font-size: 14px;
  line-height: 17px;
  color: #000;
  font-weight: bold;
  padding-left: 10px;
}

.rankingList .rankingListItem .name {
  font-size: 14px;
  line-height: 17px;
  color: #000;
  font-weight: bold;
}

.rankingList .rankingListItem .point{
  margin-left: auto;
  font-size: 14px;
  line-height: 17px;
  color: #828282;
  padding-right: 10px;
}

.flex-row {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}
.flex-row .viewBox{
  width: 50%;
  margin-right: 15px;
}

.flex-row .viewBoxX2 {
  width: 49.5%;
}

.flex-row .viewBoxRanking{
  width: 50%;
  margin-right: 15px;
}

.flex-row .viewBox:last-child{
  margin-right: 0;
}

.flex-row-button a {
  text-decoration: none;
  color:#000;
}

.flex-row-button {
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
  width: 100%;
}

.flex-row-button .buttonHolder {
  width: auto !important;
  margin-left: 20px;
}

.selectItems {
  border: 1px solid #828282;
  border-radius: 3px;
  height: 200px;
  overflow-y: scroll;
  margin-top: 20px;
}


.tabList {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.tab {
  font-weight: 400;
  cursor: pointer;
  margin-right: 10px;
  margin-left: 10px;
  padding-bottom: 5px;
}

.activeTab {
  font-size: 18px;
  height: 30px;
  padding-bottom: 5px;
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
  font-weight: bold;
  border-bottom: 7px solid #FFE16A;
}

.marginTop20 {
  margin-top: 20px;
}


.smallButton {
  padding-right: 20px;
  padding-left: 20px;
  text-align: center;
  line-height: 45px;
  cursor: pointer;
  background-color: #FFE16A;
  height: 45px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 10px;
  transition: box-shadow .3s;
}

.smallBackButton {
  padding-right: 10px;
  padding-left: 10px;
  text-align: center;
  line-height: 18px;
  cursor: pointer;
  background-color: #FFE16A;
  height: 20px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 10px;
  transition: box-shadow .3s;
}

.smallButton:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.3);
}

.buttonStyle {
  padding-right: 20px;
  padding-left: 20px;
  text-align: center;
  line-height: 45px;
  cursor: pointer;
  background-color: #FFE16A;
  height: 45px;
  font-size: 18px;
  color: #000;
  font-weight: bold;
  border-radius: 10px;
  transition: box-shadow .3s;

}

.minWidthAuto {
  min-width: auto !important;
}

.noBorderRight {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.noBorderLeft {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.buttonStyle:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.3);
}



.buttonStyle:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.3);
}

.buttonStyle.yellow{
  color: #000;
  margin-bottom: 20px;
}

.buttonStyle.gray{
  color: #000;
  background: #F2F2F2;
}

.buttonStyle.red {
  color: #fff;
  width: 100%;
  background-color: #F86D70;
  margin-bottom: 20px;
}

.buttonStyle.blue{
  color: #fff;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #7CB4FF;
}

.buttonStyle.green{
  width: 100%;
  margin-bottom: 20px;
  color: #fff;
  background-color: #27AE60;
}

.smallButton.green{
  color: #fff;
  background-color: #27AE60;
}

.smallButton.dark {
  background: #3E4649;
  color: #fff;
}

.buttonStyle.lightGreen{
  background-color: #8CEEB6;
  color: #000;
  margin-bottom: 20px;
  width: 100%;
}

.buttonStyle.dark{
  background: #3E4649;
  color: #fff;
  margin-bottom: 20px;
}


.avatarOverAvatar img{
  margin-left: -10px;
}
.avatarOverAvatar img:first-child{
  margin-left: 0;
}


.deleteButton {
  background-color: #EB5757;
  color: #fff;
}

.buttonHolder {
  width: 100%;
  justify-content: flex-end;
  display: flex;
  cursor: pointer;
}

.isDisabled{
  cursor: not-allowed;
  background-color: #e0e0e0 !important;
}
.isDisabled:hover{
  background-color: #e0e0e0 !important;
  box-shadow: none !important;
}

@keyframes placeHolderShimmer{
  0%{
    background-position: -468px 0
  }
  100%{
    background-position: 468px 0
  }
}
.linear-background {
  animation-duration: 1s;
  margin-bottom: 5px;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 1000px 104px;
  height: 10px;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.linear-background.dark {
  background: #040303;
  background: linear-gradient(to right, #040303 8%, #1a1a1a 18%, #212121 33%);
}

.newNotification {
  position: absolute;
  background-color: #EB5757;
  right: 30px;
  top: 0;
  width: 15px;
  height: 15px;
  font-size: 12px;
  text-align: center;
  color: #f2f2f2;
  font-weight: bold;
  border-radius: 20px;
}
.notHolder{
  position: relative;
}

.notificationList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.noNotification {
  font-weight: bold;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.notification {
  flex-direction: row;
  display: flex;
  margin-bottom: 10px;
  padding: 10px 25px 10px 10px;
  border-left-width: 0;
  border-right-width: 0;
  cursor: pointer;
}

.notification .inner {
  flex-direction: column;
  display: flex;
}

.notClicked {
  background-color: #FFF7CD;
}

.notClickedDark {
  background-color: #001E3C;
}

.notification:last-child {
  margin-bottom: 0;
}

.notificationShowAllButton {
  border-top-width: 0px;
  border-style: solid;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
  position: fixed;
  width: 220px;
  top:355px;
  font-weight: bold;
  cursor: pointer;
  overflow: hidden;
  z-index: 9999;
  /*border-color: #E0E0E0;*/
  padding: 10px;
  margin-left: 15px;
  height: 45px;
  text-align: center;
  color: #4F4F4F;
}


.notification .content {
  font-size: 15px;
  width: 100%;
  color: #4F4F4F;
  font-weight: bold;
  margin-left: 10px;
}

.notification .time {
  font-size: 12px;
  color: #4F4F4F;
  text-align: right;
  margin-top: 5px;
}

.notification .leftTime {
  text-align: left;
  margin-left: 10px;
}

.headerContainer {
  font-weight: 600;
  font-size: 18px;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #000000;
}

.uploadBoxFull {
  width: 100%;
  border: 1px dotted;
  border-radius: 5px;
  height: auto;
  margin-top: 10px;
  cursor: pointer;
  display: flex;
  justify-content:center;
  align-items: center;
  overflow: hidden;
}

.uploadBoxFull div {
  text-align: center;
  justify-content: center;
  line-height: 9;
}

.uploadBoxFull img {
  max-height: 155px;
  object-fit: cover;
  flex-shrink: 0;
}

.checkboxSelectionHolder {
  border-radius: 12px 0 0 12px;
  border-width: 1px;
  border-style: solid;
  border-color: #828282;
  width: 100%;
  height: 220px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.hiddenElement {
  display: none !important;
}

.marginTop20 {
  margin-top: 20px !important;
}

.marginTop10 {
  margin-top: 10px !important;
}

.marginTopMinus10 {
  margin-top: -10px !important;
}

.marginLeft10 {
  margin-left: 10px !important;
}

.marginRight10 {
  margin-right: 10px !important;
}

.singleCheckWithName {
  align-self: flex-start;
  margin-top: 4px;
  font-weight: bold;
  margin-left: -5px;
}

.boxWithText {
  border-width: 1px;
  border-style: solid;
  border-color: #828282;
  border-radius: 12px;
  align-self: center;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 200px;
  overflow: hidden;
  line-height: 200px;
}


.flex-row-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}



.flex-row-input .MuiFormControl-root{
  margin-top: 10px !important
}

.fullWidthSelect {
  width: 100%;
  height: 50px;
  min-height: 50px !important;
}


.boxHeader {
  display: flex;
  flex-direction: row;
}

.boxHeader .MuiOutlinedInput-root {
  margin-left: 20px;
  margin-top: -10px;
  width: 100%;
}
.boxHeader .MuiOutlinedInput-input {
  height: 5px;

  width: 100%;
}



.selectItems {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start
}


.selectItems .selectItem {
  min-width: 33%;
}

.buttonSmallText {
  display: block;
  position: absolute;
  margin-top: 65px;
  font-size: 12px;
}

.newEditBox {
  border: none !important;
  color: #FFE16A;
  font-weight: bold;
  font-size: 24px;
}
.newEditBox img {
  height: 394px;
  max-height: 394px;
  min-width: 400px;
  width: 100%;
}
.buttonDisabled {
  min-width: 200px;
  padding-right: 20px;
  padding-left: 20px;
  text-align: center;
  line-height: 45px;
  cursor: not-allowed;
  background-color: #F2F2F2;
  height: 45px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 10px;
  transition: box-shadow .3s;
}

.hasText {
  margin-bottom: 12px;
}
.pdfFileInBox {
  align-content: flex-start;
  line-height: 20px;
}

.pdfFileInBox p{
  display: block;
  line-height: 47px;
}


.clearMe {
  clear: both;
}

.step {
  height: auto;
}

.stepInner {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  height: auto;
}
.stepInner .startEvent{
  width: 22%;
}

.stepInner .execution{
  width: 22%;
}

.stepInner .deadline {
  width: 17%;
}

.stepInner .videoBox{
  width: 30%;
}
.stepInner .deleteBox{
  margin-top: 3px;
  width: 7%;
}


.stepName {
  width: 99%;
  text-align: left;
  border-bottom: 1px solid #E0E0E0;
  line-height: 0.1em;
  margin: 30px 0 20px;
  padding-left: 20px;
}

.stepName span {
  font-size: 14px;
  background:#fff;
  padding:0 20px;
}

.notificationHolder::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}
.notification:last-child{
  padding-bottom: 50px;
}
.notificationHolder {
  min-height: 330px;
  max-height: 300px;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  overflow-x: hidden;
  border-radius: 10px;
  margin-left: -10px;
  z-index: 9;
  margin-top: 10px;
  position: absolute;
  width: 250px;
  height: auto;
  border-width: 1px;
  border-color: #e0e0e0;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

}

.notificationIcon {
  background-color: #FFE16A;
  border-radius: 50px;
  width: 40px;
  min-width: 40px;
  height: 40px;
  display: flex;
  align-content: center;
  justify-content: center;
}
.notificationIcon img{
  justify-self: center;
}

.tabBox {
  width: 250px;
  height: 297px;
  box-shadow: 0px 8px 16px rgba(145, 158, 171, 0.24);
  background: #FFF7CD;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  box-shadow: 0px 8px 16px rgba(145, 158, 171, 0.24);
  border-radius: 10px;
  margin-bottom: 20px;
  cursor: pointer;
  margin-right: 50px;
}

.boxTextArea {
  background-color: #fff;
  height: 95px;
  padding: 10px;

  border-radius: 0px 0px 10px 10px;
}

.tabBoxes {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  align-content: flex-start;
}


.tabBoxInfo ul {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 10px;
}
.tabBoxInfo ul li {
  display: flex;
}

.tabBoxInfo ul li .activeBox {

  margin-right: 10px;
  width: 5px;
  height: 5px;
  margin-top: 6px;
  background-color: #6FCF97;
  border-radius: 10px;
}

.tabBoxInfo ul li p {
  /*color: #000;*/
  font-weight: bold;
}

.tabBoxInfo ul li .inactiveBox {

  margin-right: 10px;
  width: 5px;
  height: 5px;
  background-color: #C4C4C4;
  margin-top: 6px;
  border-radius: 10px;
}

.tabBoxInfo ul li p{
  margin: 0 0 0 15px;
  padding:0;
}

.tabBoxName {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  /*color: #000000;*/
}

.tabIcon {
  height: 210px;
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: #FFF7CD;
}
.tabIcon img {
  height: auto;
  max-width: 200px;
  justify-content: center;
  align-self: center;
}

.breadCrumb {
  width: 60%;
  list-style: none;
  display: flex;
  flex-direction: row;
}
.breadCrumb li {

  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
}

.breadCrumb li:last-child {
  font-size: 14px;
  line-height: 17px;
  color: #BDBDBD;
  cursor: initial;
}

.breadCrumb li.divider{
  width: 5px;
  height: 5px;
  background-color: #BDBDBD;
  border-radius: 10px;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 7px;
}

.navBarItem:hover {
  background-color: #FFF7CD !important;
}

.navBarItem.dark:hover{
  background-color: #0A1929 !important;
}

.leftNavBar {
  /*background: #E0E0E0 !important;*/
  padding-top: 20px;
  border-radius: 10px;
  margin-left: 20px;
  position: relative;
}


.flex-content {
  margin-top: 30px;
  flex-direction: row;
  width: 100%;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
}
.contentInner {
  transition: box-shadow .3s;
  cursor: pointer;
  margin-left: 1%;
  margin-right: 1%;
  margin-bottom: 20px;
  width: 350px;
  height: 250px;
  border-radius: 10px;
  position: relative;
}

.contentInner:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.8);
}

.content-text {
  position: absolute;
  bottom: -17px;
  padding: 10px;
  min-height: 70px;
  justify-content: center;
  align-items: center;
  align-content: center;
  display: flex;
  width: 100%;
  background-color: rgba(0,0,0,.3);
  color: #fff;
  font-weight: bold;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  font-size: 18px;
  text-align: center;
}

.content-text p {
  float: left;
}

.content-text .finished{
  float: right;
  width: 7px;
  height: 7px;
  background-color: #27AE60;
  border-radius: 20px;
}

.content-picture {

  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;

}

.alert {
  width: 100%;
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
}

.profileImageOnUpload {
  height: 100px;
  width: 100px;
  border-radius: 50px;
  justify-content: center;
  align-self: center;
  margin: 30px 0;
}

.uploadBoxImage {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
}

.uploadBoxImage .buttonHolder {
  justify-content: center;
}

.rankingHeader {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 245px;
  width: 100%;

  background-repeat: no-repeat;

  background-position: center;
}

.uploadBoxMultiple{
  border: 1px dashed #000;
  width: 100%;
  height: 250px;
  cursor: pointer;
}
.dragging {
  border: 2px dashed #000;
}
.uploadBoxMultiple p {
  text-align: center;
  line-height: 230px;
}

.uploadingContent {
  margin: 0;
  padding: 0;
}

.uploadingContent li {
  list-style: none;
}
.uploadingContent li .liInside {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.uploadingContent li .bar {
  background-color: #27AE60;
  margin-bottom: 2px;
  height: 3px;
  display: block;
  clear: both;
}

.uploadingContent li:last-child {
  border-bottom: none;
}

.uploadingContainer {
  display: none;
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 400px;
  height: 250px;
  overflow-y: auto;
  border: 1px solid #e6e6e650;
  border-radius: 15px;
  z-index: 9998;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
}

.uploadingContainer li p{
  font-size: 13px;
}
.uploadingContainer ul {
  padding: 15px !important;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 9999;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.footerTable {
  margin-top: 20px;
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}

.footerTable p {
  font-weight: bold;
  font-size: 14px;
  margin-right: 30px;
}
.footerTable p:last-child {
  margin-right: 5px;
}

.selectItem {
  border: 0;
  background: none;
}

.footerTable img {
  margin-left: 25px;
  justify-content: center;
  align-self: center;
  justify-self: center;
  margin-top: 15px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.headerLogo{
  position: absolute;
  top: 10px;
  right: 10px;
}
.headerLogo img {
  width: 200px;
  height: 42px;
}

.headerRightHolder {
  height: 100px;
  margin-right: 50px;
  align-items: center;
  display: flex;
  margin-left: 75px;
}

.hideSmall {
  position: absolute;
  right: 0;
  top: -72px;
  z-index: 1;
}

@media only screen and (max-width: 700px) {
  .flex-row-button {
    flex-direction: column;
    justify-content: flex-end;
    display: flex;
    width: 100%;
    margin-top: 50px;
  }


}

@media only screen and (max-width: 1000px) {

  .flex-row-button {
    flex-direction: column;
    justify-content: flex-end;
    display: flex;
    width: 100%;
  }
  .flex-row-button .buttonHolder {
    margin-bottom: 10px;
  }
  .headerRightHolder {
    height: 100px;
    width: auto;
    align-items: center;
    display: flex;
    margin-left: 0;
  }
  .headerLogo{
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .trophyListHolder {
    height: 400px;
    width: auto;
    display: flex;
    overflow-x: hidden;
    overflow-y: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .fromMyHolder {
    height: 240px;
    top:0;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: initial;
    align-items: center;
  }
  .fromMyHolder .trophyHolder:first-child{
    margin-top: 0 !important;
  }

  .trophyHolder {
    margin-top: 20px;
  }
  .trophyHolder:first-child{
    margin-top: 300px;
  }

  .contentInner {
    margin-bottom: 20px;
    width: 100%;
    height: auto;
    border-radius: 10px;
    position: relative;
  }


  .tabBoxes {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
    width: 100%;
    flex-wrap: initial;
    clear: both;
    margin-top: 20px;
  }

  .tabBox {
    cursor: pointer;
    width: 100%;
    clear: both;
    /*height: 350px;*/
    background: #FFF7CD;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    box-shadow: 0px 8px 16px rgba(145, 158, 171, 0.24);
    border-radius: 10px;
    margin-bottom: 10px;
  }



  .flex-row {
    flex-direction: column;
    justify-content: space-between;
    display: flex;
  }
  .flex-row .viewBoxRanking {
    width: 94%;
  }
  .viewBoxRanking {
    width: 94%;
  }
  .flex-row .viewBox{
    width: 90%;
  }
  .viewBox {
    width: 90%;
  }
  .exportTable td{
    padding-top: 10px;
  }

  .flex-row-input {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }



  .flex-row-input .marginRight10 {
    margin-right: 0 !important;
  }

  .flex-row-input .marginLeft10 {
    margin-left: 0 !important;
  }

  .boxHeader {
    display: flex;
    flex-direction: column;
  }

  .boxHeader .MuiOutlinedInput-root {
    margin-top: 10px;
    margin-left: 0px;
    width: 100%;
  }
  .boxHeader .MuiOutlinedInput-input {
    height: 5px;

    width: 100%;
  }


  .selectItems .selectItem {
    min-width: 100%;
  }

  .stepInner {
    flex-direction: column;
    display: flex;
    justify-content: space-between;
  }
  .stepInner .startEvent{
    width: 100%;
    margin-top: 15px;
  }

  .stepInner .execution{
    width: 100%;
    margin-top: 15px;
  }

  .stepInner .deadline {
    width: 100%;
    margin-top: 15px;
  }
  .stepInner .videoBox{
    margin-top: 15px;
    width: 100%;
  }

}

.playPause {
  cursor: pointer;
  position: absolute;
  z-index: 99;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-self: center;
}

.onlyHoverVideo:hover .onlyHover{
  cursor: pointer;
  position: absolute;
  z-index: 99;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-self: center;
}


.currentTime {
  position: absolute;
  padding: 5px;
  top: 3px;
  background-color: #00000050;
  right: 0;
  display: flex;
  color: #fff;
  justify-content: flex-end;
  align-items: flex-end;
}



.onlyHover {
  display: none;
}

.requiredDepsText {
  text-align: center;
  line-height: 120px;
  font-size: 15px;
  font-weight: bold;
  font-style: italic;
}

.marginTop50 {
  margin-top: 50px;
}
.classEditorEmail{
  height: 600px;
}
.classEditorEmailSmall{
  height: 350px;
}
.classEditorInnerSmall {
  height: 250px !important;
}

.buttonTXPlaceholder {
  margin: 5px;
  box-shadow: 0 8px 16px rgba(145, 158, 171, 0.24);
  border: 1px solid #E0E0E0;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  transition: box-shadow .3s;
}
.buttonTXPlaceholder:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.3);
}

.classEditorInner {
  height: 450px !important;
}
.borderLine {
  width: 250px;
  border: 1px solid #000000;
}

.headerTextConfig {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
}

.loginBox {

  /*box-shadow: 0px 8px 10px rgba(145, 158, 171, 0.4);
  backdrop-filter: blur(10px); */
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 10px;
  padding: 30px 20px 50px 20px;
  position: relative;
}
.bottomLinks {
  font-size: 14px;
  line-height: 17px;
  flex-direction: row;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 10px;
  color: #fff;
  right: 20px;
}
.bottomLinks a {
  text-decoration: none;
  cursor: pointer;
  color: #fff;
  margin-left: 20px;
  display: flex;
  justify-content: flex-end;

}

.bottomCube {
  position: absolute;
  bottom: -6px;
  border-bottom-right-radius: 10px;
  right: 0;
  z-index: 99;
}

.bottomCube img {
  border-bottom-right-radius: 10px;
}

.languages {
  display: flex;
  flex-direction: row;
  list-style: none;
  padding: 0;
  margin: 0 0 10px;
}

.languages li {
  margin-right: 17px;
  cursor: pointer;
  color: #9e9e9e;
}

.inputFlex {
  flex-direction: row;
  display: flex;
}


video::-webkit-media-controls-panel {
  display: none !important;
  opacity: 1 !important;}

video::-webkit-media-controls {
  display: none;
}

/* Could Use thise as well for Individual Controls */
video::-webkit-media-controls-play-button {}

video::-webkit-media-controls-volume-slider {}

video::-webkit-media-controls-mute-button {}

video::-webkit-media-controls-timeline {}

video::-webkit-media-controls-current-time-display {}


.tableViewUser {
  background: #F2F2F2;
  border-radius: 10px;
  height: 60px;
  border-collapse: collapse;

}
.tableViewUser th{
  border-collapse: collapse;
  border: none;
  text-align: left;
  padding-left: 20px;
}

.tableViewUser th:first-child{
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.tableViewUser th:last-child{
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.tableInnerViewUser tr {
  padding-top: 10px;
}

.tableInnerViewUser td {
  padding-left: 20px;
  padding-top: 15px;
}


